<template>
  <div>
    <b-modal
      id="add-edit-vacation-days"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditVD')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-tabs content-class="mt-3">
        <b-tab
          :title="$t('EditAdd/Sub')"
          active
        >
          <template #title>
            <strong style="color: #0071AE">{{ $t("EditAdd/Sub") }}</strong>
          </template>
          <b-form>
            <b-form-group
              id="input-group-8"
              :label="$t('EditY')"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="edit.selectedYear"
                required
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('EditRD')"
              label-for="input-25"
            >
              <b-form-input
                id="input-8"
                v-model.number="edit.numberOfDays"
              />
            </b-form-group>
          </b-form>
          <b-form class="form2">
            <div>
              <b-button
                type="button"
                variant="none"
                class="buttonSubmit"
                @click="onSubmitEditVacation"
              >
                {{ $t('VacationSubmit') }}
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="buttonCancel"
                style="margin-right: 15px"
                @click="onCancel"
              >
                {{ $t('VacationCancel') }}
              </b-button>
            </div>
          </b-form>
        </b-tab>
        <b-tab :title="$t('Move')">
          <template #title>
            <strong style="color: #0071AE">{{ $t("Move") }}</strong>
          </template>
          <p>{{ $t("EditDaysAmountRD") }} <b>{{ getRemainingDays }}</b></p>
          <b-form>
            <b-form-group
              id="input-group-8"
              :label="$t('EditFY')"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="move.selectedYear"
                required
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('EditTY')"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="move.nextSelectedYear"
                required
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('EditNumberDaysM')"
              label-for="input-8"
            >
              <b-form-input
                id="input-8"
                v-model.number="move.numberOfDays"
                :max="getRemainingDays"
              />
            </b-form-group>
          </b-form>
          <b-form class="form2">
            <div>
              <b-button
                type="button"
                variant="none"
                class="buttonSubmit"
                :disabled="move.numberOfDays>getRemainingDays"
                @click="onSubmitMoveVacation"
              >
                {{ $t('VacationSubmit') }}
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="buttonCancel"
                style="margin-right: 15px"
                @click="onCancel"
              >
                {{ $t('VacationCancel') }}
              </b-button>
            </div>
          </b-form>
        </b-tab>
        <!-- <b-tab
          title="Pay"
        >
          <b-form>
            <b-form-group
              id="input-group-8"
              label="Select Year"
              label-for="input-25"
            >
              <vue-select
                id="input-5"
                v-model="pay.selectedYear"
                required
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              label="Remaining days"
              label-for="input-25"
            >
              <b-form-input
                id="input-8"
                v-model.number="pay.numberOfDays"
              />
            </b-form-group>
          </b-form>
          <b-form class="form2">
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="margin-right: 15px"
              @click="onCancel"
            >
              {{ $t('VacationCancel') }}
            </b-button>
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              :disabled="getActiveRouteById.length != 0 || getHasVacation == true"
              @click="onSubmitPayVacation"
            >
              {{ $t('VacationSubmit') }}
            </b-button>
          </b-form>
        </b-tab> -->
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import 'vue2-datepicker/index.css';
import moment from 'moment';

import {
// required,
} from 'vuelidate/lib/validators';

export default {
  components: {
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedId', 'userObj'],
  data() {
    return {
      user: {},
      selectedItemId: null,
      UserId: '',
      edit: {
        selectedYear: null,
        numberOfDays: '',
      },
      move: {
        selectedYear: null,
        nextSelectedYear: null,
        numberOfDays: '',
      },
      pay: {
        selectedYear: null,
        numberOfDays: '',
      },
      yearsArray: [],
    };
  },
  mounted() {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Create an array with the last year, current year, and next 5 years
    for (let i = -1; i <= 5; i++) {
      this.yearsArray.push(currentYear + i);
    }

    // Set the selected year to the current year
    this.edit.selectedYear = currentYear;
    this.move.selectedYear = currentYear;
    this.pay.selectedYear = currentYear;
  },
  // validations: {
  //   time1: {
  //     required,
  //   },
  //   time2: {
  //     required,
  //   },
  //   reason: {
  //     required,
  //   },
  // },

  computed: {
    ...mapGetters(['getActiveRouteById', 'getRemainingDays', 'getfreeEmployees', 'getLoggedInUser', 'getFiliales', 'getFreeManagers', 'getHasVacation']),
    remainingDays() {
      return this.getRemainingDays;
    },
  },
  watch: {
    remainingDays: {
      // immediate: true, // update the data property immediately on component mount
      handler(newVal) {
        this.edit.numberOfDays = newVal
        this.pay.numberOfDays = newVal
      },
      immediate: true,
    },
    // eslint-disable-next-line func-names
    'edit.selectedYear': function (value) {
      if (this.edit.selectedYear != null && this.UserId != '') {
        this.remainingDaysOfEmployee({ employeeUserId: this.UserId, year: value });
      }
    },
    // eslint-disable-next-line func-names
    'move.selectedYear': function (value) {
      if (this.edit.selectedYear != null && this.UserId != '') {
        this.remainingDaysOfEmployee({ employeeUserId: this.UserId, year: value });
      }
    },
    // eslint-disable-next-line func-names
    'pay.selectedYear': function (value) {
      if (this.edit.selectedYear != null && this.UserId != '') {
        this.remainingDaysOfEmployee({ employeeUserId: this.UserId, year: value });
      }
    },
  },
  created() {
    // console.log('sadafs', this.user);
  },
  methods: {
    ...mapActions(['editVacationDays', 'loadVacationRequests', 'remainingDaysOfEmployee', 'payVacationDays', 'moveVacationDays', 'payVacationDays', 'getAlreadyRequestedVacation', 'releaseAndReplaceEmployeeFromTour', 'addCoManagerToStores', 'freeEmployee', 'releaseEmployeeFromTour', 'loadFilialePagination', 'getTourNameByEmployeeUserIdStartAndEndShift']),
    // updateYear(year) {
    //   this.selectedYear = Number(new Date(Number(year), 0, 1).getFullYear())
    // },
    handleModalShown() {
      // Call your method here
      this.UserId = this.selectedId
    },
    async loadFiliale() {
      await this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 50,
      });
    },

    async replaceEmployee(value) {
      await this.releaseAndReplaceEmployeeFromTour(
        {
          obj: {
            truckStaffId: value,
            // tourTruckId: this.selectedItemId,
            storeId: null,
            substituteEmployee: this.substituteEmployee.userId,
            message: '',
            dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          },
          successCallback: () => {
            this.getTourNameByEmployeeUserIdStartAndEndShift({
              employeeUserId: this.form.employeeUserId, startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '-'), endShift: null,
            });
            this.substituteEmployee = null;
            this.substituteTeamLeader = null;
          },
        },
      );
    },
    handleReleaseClick() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to release this employee?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Release',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.releaseEmployee();
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async releaseEmployee() {
      await this.releaseEmployeeFromTour({
        object: {
          tourTruckId: null,
          employeeId: this.editId.id,
          message: '',
        },
        successCallback: () => {
          this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: this.form.employeeUserId, startShift: '2023-02-14', endShift: '2023-02-14' });
        },
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitEditVacation() {
      await this.editVacationDays({
        employeeUserId: this.UserId,
        numberOfDays: this.edit.numberOfDays,
        year: this.edit.selectedYear,
        successCallback: () => {
          this.loadVacationRequests({ employeeId: this.selectedId, year: this.edit.selectedYear });
        },
      })
      this.$refs.modal.hide()
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    async onSubmitMoveVacation() {
      this.moveVacationDays({
        employeeUserId: this.UserId,
        currentYear: this.move.selectedYear,
        newYear: this.move.nextSelectedYear,
        amountOfDays: this.move.numberOfDays,
        successCallback: () => {
          this.loadVacationRequests({ employeeId: this.selectedId, year: this.move.selectedYear });
        },
      })
      this.$refs.modal.hide()
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    async onSubmitPayVacation() {
      this.payVacationDays({
        employeeUserId: this.UserId,
        numberOfDays: this.pay.numberOfDays,
        year: this.pay.selectedYear,
        successCallback: () => {
          this.loadVacationRequests({ employeeId: this.selectedId, year: this.pay.selectedYear });
        },
      })
      this.$refs.modal.hide()
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.selectedItemId = null;
      this.numberOfDays = this.getRemainingDays;
      this.selectedYear = new Date().getFullYear();
      this.move.nextSelectedYear = null;
      this.pay.selectedYear = new Date().getFullYear();
      this.edit.selectedYear = new Date().getFullYear();
      this.move.numberOfDays = '';
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.vacation{
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr ;

}
.form2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 2px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
