<template>
  <div>
    <b-modal
      id="leave-request"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('VacationLeaveReq')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <p v-if="userObj.personRole == 'Manager'">
          {{ $t("VacationSubstitue") }} <b>{{ userObj.firstName }} {{ userObj.lastName }}.</b>
        </p>
        <b-form-group
          v-if="userObj.personRole == 'Manager'"
          id="input-group-5"
          :label="$t('VacationSubtituteManager')"
          label-for="input-2"
        >
          <vue-select
            id="input-5"
            v-model="manager"
            required
            :options="filteredManagers"
            label="userFullName"
            :value="userId"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.reason.required"
            style="color: red"
          >Please select a manager.</span>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="$t('VacationVacationT')"
          label-for="input-2"
        >
          <vue-select
            id="input-5"
            v-model="reason"
            required
            :options="[
              { value: 'Vacation', label: $t('Vacation') },
              { value: 'SickLeave', label: $t('AddNew19') },
              { value: 'AccidentLeave', label: $t('AddNew20') },
              { value: 'Education', label: $t('AddNew21') },
              { value: 'SpecialLeave', label: $t('AddNew22') },
              { value: 'Maternity', label: $t('AddNew23') },
              { value: 'Appointment', label: $t('AddNew24') },
              { value: 'DeathCase', label: $t('AddNew46') },
            ]"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.reason.required"
            style="color: red"
          >Please select a reason.</span>
        </b-form-group>
        <b-form-group
          v-if="showInput"
          id="input-group-8"
          label="Other reason"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model="form.vacationType"
            style="height: 80px"
          />
        </b-form-group>
      </b-form>
      <!-- <b-form-group>

        <b-form-textarea
          id="input-15"
          v-model="form.notes"
        />

      </b-form-group> -->
      <b-form>
        <b-form-group
          id="input-group-4"
          :label="$t('VacationStartDate')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="form.startDate"
            type="date"
          /> -->
          <date-picker
            v-model="time1"
            type="date"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time1.required"
            style="color: red"
          >Please select a date.</span>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="$t('VacationEndDate')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="form.endDate"
            type="date"
          /> -->
          <date-picker
            v-model="time2"
            type="date"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time2.required"
            style="color: red"
          >Please select a date.</span>
        </b-form-group>
      </b-form>
      <span
        v-if="getHasVacation == true"
        style="color: red"
      >There is already a leave request between these dates!</span>
      <div
        v-for="item in getPlannedDaysByEmployee"
        :key="item.tourTruckId"
      >
        <!-- <p style="margin-top: 20px;">
          There is a scheduled route for this employee between the dates of <b style="color: black;">{{ new Date(item.startDate).toLocaleDateString('en-GB') }} - {{ new Date(item.endDate).toLocaleDateString('en-GB') }}</b>
        </p> -->
        <br>
        <div
          style="
          background-color: gainsboro;
          border-radius: 8px;
          max-width: 100%;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
        >
          <div
            style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 10px;"
          >
            <b-form-group
              id="input-group-8"
              label="Date"
              label-for="input-25"
              style="margin-right: 5px; text-align: left;"
            >
              {{ dateModified(item.plannedDate) }}
            </b-form-group>
            <b-form-group
              id="input-group-8"
              label="Is in Route"
              label-for="input-25"
              style="text-align: left;"
            >
              {{ item.truckStaffId.length !== 0 ? $t('Yes') : $t('No') }}
            </b-form-group>
            <div style="display: flex; flex-direction: column;">
              <!-- v-if="item.truckStaffId.length !== 0" -->
              <b-button
                type="button"
                variant="none"
                style="margin-bottom: 5px"
                class="buttonSubmit"
                @click="replaceShow(item)"
              >
                Replace
              </b-button>
              <b-button
                v-if="item.employeeCounter !== 1"
                type="button"
                variant="none"
                class="buttonSubmit"
                @click="handleReleaseClick(item), selectedItemId = item.tourTruckId"
              >
                Remove
              </b-button>
            </div>
          </div>
          <!-- <b-form-group
            v-if="replace == true && getLoggedInUser.role !== 'TeamLeader' && item.tourTruckId === selectedItemId"
            id="input-group-8"
            style="width: 100%; padding-top: 20px; border-top: 2px solid black;"
            label="Select Filiale"
            label-for="input-25"
          >
            <vue-select
              id="input-8"
              v-model="storeIds"
              :options="getFiliales"
              label="storeName"
              :value="storeId"
            />
          </b-form-group> -->
          <b-form-group
            v-if="replace == true && item.tourTruckId === selectedItemId"
            id="input-group-8"
            style="width: 100%; padding-top: 20px;"
            label="Select Free Employee"
            label-for="input-25"
          >
            <vue-select
              id="input-8"
              v-model="substituteEmployee"
              :options="getFreeEmployeesForManagerByStoreId"
              label="userFullName"
            />
          </b-form-group>
          <b-button
            v-if="replace == true && item.tourTruckId === selectedItemId"
            type="button"
            variant="none"
            style="margin-top: 10px; width: 100px;"
            class="buttonSubmit"
            @click="replaceEmployee(item)"
          >
            Save
          </b-button>
        </div>
      </div>
      <b-form class="form2">
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="getPlannedDaysByEmployee.length != 0 || getHasVacation == true"
            @click="onSubmitRequestVacation"
          >
            {{ $t('VacationSubmit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t('VacationCancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

import {
  required,
} from 'vuelidate/lib/validators';

export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedId', 'userObj'],
  data() {
    return {
      replace: false,
      manager: '',
      user: {},
      substituteEmployee: null,
      selectedItemId: null,
      reason: '',
      showInput: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      time1: null,
      formSubmitted: false,
      time2: null,
      hasVacation: false,
      isPhoneNumberValid: null,
      vacationTypes: [
        { value: 'vacation', text: this.$t('Vacation') },
        { value: 'addNew19', text: this.$t('AddNew19') },
        { value: 'addNew20', text: this.$t('AddNew20') },
        { value: 'addNew21', text: this.$t('AddNew21') },
        { value: 'addNew22', text: this.$t('AddNew22') },
        { value: 'addNew23', text: this.$t('AddNew23') },
        { value: 'addNew24', text: this.$t('AddNew24') },
        { value: 'addNew46', text: this.$t('AddNew46') },
      ],
      form: {
        employeeUserId: this.selectedId,
        startDate: null,
        endDate: null,
        vacationType: '',
      },
      form2: {
        managerId: this.selectedId,
        coManagerId: '',
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
    };
  },
  validations: {
    time1: {
      required,
    },
    time2: {
      required,
    },
    reason: {
      required,
    },
  },

  computed: {
    ...mapGetters(['getFreeEmployeesForManagerByStoreId', 'getPlannedDaysByEmployee', 'getfreeEmployees', 'getLoggedInUser', 'getFiliales', 'getFreeManagers', 'getHasVacation']),
    // isValidDate() {
    //   return this.time1 && this.time2 && this.time1 <= this.time2;
    // },
    filteredManagers() {
      return this.getFreeManagers.filter((manager) => {
        return manager.userId !== this.userObj.userId;
      });
    },
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = new Date(this.time1.split('-').reverse().join('-')).getTime();
      const end = new Date(this.time2.split('-').reverse().join('-')).getTime();
      return start <= end;
    },

  },
  watch: {
    reason(value) {
      if (value == 'Other') {
        this.showInput = true;
        this.form.vacationType = '';
      } else {
        this.showInput = false;
        this.form.vacationType = value.value;
      }
    },


    time1(value) {
      // const res = moment.tz.guess();
      // const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      // this.form.startDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.startDate = moment.tz(value2, 'DD-MM-yyyy', res).set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    time2(value) {
      // const res = moment.tz.guess();
      // const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      // this.form.endDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.endDate = moment.tz(value2, 'DD-MM-yyyy', res).set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    // eslint-disable-next-line func-names
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
      }
    },
    // eslint-disable-next-line func-names
    'form.startDate': function (value) {
      if (this.form.endDate != null) {
        this.loadPlannedDaysByEmployee({ employeeUserId: this.form.employeeUserId, startDate: value, endDate: this.form.endDate });
        this.getAlreadyRequestedVacation({ employeeUserId: this.form.employeeUserId, startshift: value, endDate: this.form.endDate });
      }
    },
    // eslint-disable-next-line func-names
    'form.endDate': function (value) {
      this.loadPlannedDaysByEmployee({ employeeUserId: this.form.employeeUserId, startDate: this.form.startDate, endDate: value });
      this.getAlreadyRequestedVacation({ employeeUserId: this.form.employeeUserId, startDate: this.form.startDate, endDate: value });
    },
    // eslint-disable-next-line func-names
    'form.vacationType': function (newValue) {
      this.form.vacationType = newValue.replace(/\s+/g, '');
    },
    selectedId: {
      handler(value) {
        this.form.employeeUserId = value;
        this.form2.managerId = value;
      },
      immediate: true,
    },
    manager: {
      handler(value) {
        this.form2.coManagerId = value.userId;
      },
      immediate: true,
    },
  },
  created() {
    // console.log('sadafs', this.user);
  },
  methods: {
    ...mapActions(['freeEmployeesForManagerByStoreId', 'deleteEmployeePlanning', 'editEmployeePlanning', 'resetPlannedDaysByEmployee', 'loadFreeManagers', 'resetTourss', 'getAlreadyRequestedVacation', 'releaseAndReplaceEmployeeFromTour', 'addCoManagerToStores', 'freeEmployee', 'releaseEmployeeFromTour', 'loadFilialePagination', 'loadPlannedDaysByEmployee']),
    replaceShow(value) {
      // console.log(value)
      this.replace = !this.replace;
      this.selectedItemId = value.tourTruckId
      // const startDate = this.getPlannedDaysByEmployee.find((item) => { return item.tourTruckId === this.selectedItemId }).startShift;
      // const endDate = this.getPlannedDaysByEmployee.find((item) => { return item.tourTruckId === this.selectedItemId }).endShift;
      const startDate = moment(this.time1, 'DD-MM-YYYY').set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const endDate = moment(this.time2, 'DD-MM-YYYY').set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      this.freeEmployeesForManagerByStoreId({
        storeId: this.userObj.storeId, startShift: startDate, endShift: endDate,
      });
    },
    // this.form.startDate = moment.tz(value2, 'DD-MM-yyyy', res).set({ hour: 0, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    handleModalShown() {
      // Call your method here
      this.loadFiliale();
      this.form.employeeUserId = this.selectedId
      this.loadFreeManagers();
    },
    async loadFiliale() {
      await this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 50,
      });
    },

    async replaceEmployee(value) {
      console.log(value)
      if (value.truckStaffId.length !== 0) {
        await this.releaseAndReplaceEmployeeFromTour(
          {
            obj: {
              truckStaffId: value.truckStaffId[0],
              // tourTruckId: this.selectedItemId,
              storeId: this.userObj.storeId,
              substituteEmployee: this.substituteEmployee.userId,
              employeePlanId: value.employeePlanningId,
              message: '',
              dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            },
            successCallback: () => {
              const startDate = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DD')
              const endDate = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DD')
              this.loadPlannedDaysByEmployee({
                employeeUserId: this.form.employeeUserId, startDate, endDate,
              });
              this.substituteEmployee = null;
              this.substituteTeamLeader = null;
            },
          },
        );
      } else if (value.truckStaffId.length == 0) {
        this.editEmployeePlanning({
          obj: {
            employeePlanId: value.employeePlanningId,
            newEmployeeId: this.substituteEmployee.userId,
          },
          successCallback: () => {
            const startDate = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DD')
            const endDate = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DD')
            this.loadPlannedDaysByEmployee({
              employeeUserId: this.form.employeeUserId, startDate, endDate,
            });
            this.substituteEmployee = null;
            this.substituteTeamLeader = null;
          },
        })
      }
    },
    handleReleaseClick(valuee) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to release this employee?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Release',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.releaseEmployee(valuee);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async releaseEmployee(test) {
      console.log(test)
      if (test.truckStaffId.length !== 0) {
        await this.releaseEmployeeFromTour({
          obj: {
            truckStaffId: test.truckStaffId[0],
            message: '',
            dateReleased: moment().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
          successCallback: () => {
            this.loadPlannedDaysByEmployee({ employeeUserId: this.form.employeeUserId, startDate: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endDate: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
          },
        });
      } else if (test.truckStaffId.length === 0) {
        this.deleteEmployeePlanning({
          employeePlanId: test.employeePlanningId,
          successCallback: () => {
            this.loadPlannedDaysByEmployee({ employeeUserId: this.form.employeeUserId, startDate: moment.tz().set({ hour: 8, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), endDate: moment.tz().set({ hour: 17, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
          },
        })
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitRequestVacation() {
      this.formSubmitted = true
      this.$v.reason.$touch();
      if (this.$v.reason.$anyError) {
        return;
      }
      this.$v.time1.$touch();
      if (this.$v.time1.$anyError) {
        return;
      }
      this.$v.time2.$touch();
      if (this.$v.time2.$anyError) {
        return;
      }

      this.$emit('pushFormUser', this.form)
      if (this.userObj.personRole == 'Manager') { await this.addCoManagerToStores(this.form2); }
      this.$refs.modal.hide()
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    dateModified(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    onReset() {
      // Reset our form values
      this.selectedItemId = null;
      this.reason = '';
      this.form.employeeUserId = '';
      this.form.startDate = null;
      this.form.endDate = null;
      this.form.vacationType = '';
      this.time1 = '';
      this.time2 = '';
      this.notes = ''
      this.formSubmitted = false;
      this.form2.coManagerId = null;
      this.manager = null;
      this.getHasVacation = false
      this.resetTourss()
      this.$store.commit('UPDATE_LOGGEDIN_USER_VACATION', false);
      this.reason = '';
      this.resetPlannedDaysByEmployee()
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.vacation{
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr ;

}
.form2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 2px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
